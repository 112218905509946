





















































































import { TableQuery } from "@/api/requests/TableQuery";
import { GetReportsResponse } from "@/api/responses/reports/GetReportsResponse";
import ACTIONS from "@/store/action-definitions";
import { TableData } from "@/types/Table/TableData";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import ReportForm from "./ReportForm.vue";
import ReportPreview from "./ReportPreview.vue";

@Component({
	components: {
		ReportForm,
		ReportPreview
	}
})
export default class ReportsTable extends Vue {
	private tableColumns = [
		{
			prop: "id",
			label: this.$t("reports.Id"),
			minWidth: 80
		},
		{
			prop: "name",
			label: this.$t("reports.Name"),
			minWidth: 200
		},
		{
			prop: "description",
			label: this.$t("reports.Description"),
			minWidth: 200
		},
		{
			prop: "noOfProducts",
			label: this.$t("reports.NoOfProducts"),
			minWidth: 100
		},
		{
			prop: "defaultLanguageName",
			label: this.$t("reports.DefaultLanguage"),
			minWidth: 200
		}
	];
	private tableData = {
		results: [],
		count: 0,
		currentPage: 1
	} as TableData<GetReportsResponse>;
	private perPageOptions = [5, 10, 15];
	private loading = true;
	private modalShow = false;
	private modalHeader = "";
	private editedData = {};
	private editedDataIndex = 0;
	private modalPreviewShow = false;
	private fetchDataOnRemove = false;
	private idToDelete = 0;

	$refs!: {
		reportFormRef: ReportForm;
		reportPreviewRef: ReportPreview;
	};

	@Action(ACTIONS.SET_SHOW_CONFIMRATION_MODAL) setShowConfimrationModal: (
		show: boolean
	) => void;

	@Getter("getStatusConfirmationModal") statusConfirmationModal!: boolean;

	@Watch("statusConfirmationModal")
	async onStatusConfirmationModalChange(newVal: boolean) {
		if (newVal === true) {
			if (this.idToDelete !== 0) {
				const { success } = await this.$reports.removeReport(
					this.idToDelete
				);
				if (success) {
					this.fetchDataOnRemove = !this.fetchDataOnRemove;
					this.idToDelete = 0;
				}
			}
		}
	}

	async fetchData(pagination: TableQuery): Promise<void> {
		this.loading = true;
		const { data, success } = await this.$reports.getReports(pagination);
		if (success) {
			this.tableData = data;
		}
		this.loading = false;
	}

	handleEdit(id: number): void {
		this.modalHeader = this.$t("reports.ReportInfo").toString();
		this.$refs.reportFormRef.fill(id);
		this.modalShow = true;
	}

	handleShow(id: number): void {
		this.$refs.reportPreviewRef.fill(id);
		this.modalPreviewShow = true;
	}

	handleReportEditor(id: number): void {
		const reportEditorTab = this.$router.resolve({
			name: "reportEditor",
			query: { reportId: id.toString() }
		});
		window.open(reportEditorTab.href, "_blank");
	}

	async handleRemoveReport(id: number): Promise<void> {
		this.idToDelete = id;
		this.setShowConfimrationModal(true);
	}

	closeModal(): void {
		this.modalShow = false;
		this.fetchData({
			page: 1,
			pageSize: 5,
			sortDirection: "descending",
			sortColumn: this.tableColumns[0].prop,
			searchQuery: "",
			shouldSort: false
		} as TableQuery);
	}
}
