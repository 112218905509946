import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GetAssessmentContext } from "../responses/assessments/GetAssessmentContext";

export class Assessment extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getAssessmentContext(): Promise<ApiResponse<GetAssessmentContext>> {
		return this.get<
			ApiResponse<GetAssessmentContext>,
			AxiosResponse<ApiResponse<GetAssessmentContext>>
		>("/Assessment")
			.then(this.success)
			.catch(this.error);
	}
}
