import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { Languable } from "@/interfaces/store/Languable";
import { Language } from "@/types/Language";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TableQuery } from "../requests/TableQuery";

export class Languages extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getLanguages(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<Language>>> {
		return this.get<
			ApiResponse<TableData<Language>>,
			AxiosResponse<ApiResponse<TableData<Language>>>
		>("/Languages", {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}

	public getSupportedLanguages(): Promise<ApiResponse<Languable[]>> {
		return this.get<
			ApiResponse<Languable[]>,
			AxiosResponse<ApiResponse<Languable[]>>
		>("/Languages/supported")
			.then(this.success)
			.catch(this.error);
	}
}
