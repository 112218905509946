
































import i18n from "@/locales/i18n";
import ACTIONS from "@/store/action-definitions";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component
export default class AddToCustomReports extends Vue {
	private loading = false;
	private saving = false;
	private id = 0;
	private name = "";

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	fill(id: number, name: string): void {
		this.loading = true;
		this.id = id;
		this.name = name;
		this.loading = false;
	}

	async save(): Promise<void> {
		this.saving = true;
		const { success } = await this.$reports.createReportVersion(this.id, {
			name: this.name
		});

		if (success) {
			this.setMessage({
				message: i18n.t("reports.SuccessfullyCreated").toString(),
				type: "success"
			});
			this.$emit("closeModal");
		}
		this.saving = false;
	}
}
