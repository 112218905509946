const table = {
	TotalEntries: "|FROM| - |TO| of |TOTAL|",
	NoData: "There is no data to display",
	Fetching: "Wait a moment, we're fetching data",
	Save: "Save",
	AreYouSure: "Are you sure you want to quit without saving?",
	Yes: "Yes",
	No: "No",
	Actions: "Actions",
	PleaseInput: "Please input",
	Edit: "Edit",
	Delete: "Delete",
	Add: "Add",
	Exit: "Exit",
	Cancel: "Cancel",
	RowsPerPage: "Rows per page"
};

export default table;
