






























import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Pagination extends Vue {
	@Prop({
		type: String,
		default: "primary",
		validator: (value) => {
			return [
				"default",
				"primary",
				"danger",
				"success",
				"warning",
				"info"
			].includes(value);
		}
	})
	type!: string;
	@Prop({ type: Number, default: 0 }) pageCount!: number;
	@Prop({ type: Number, default: 25 }) perPage!: number;
	@Prop({ type: Number, default: 0 }) total!: number;
	@Prop({ type: Number, default: 1 }) value!: number;

	private defaultPagesToDisplay = 25;

	get paginationClass() {
		return `pagination-${this.type}`;
	}
	get totalPages() {
		if (this.pageCount > 0) return this.pageCount;
		if (this.total > 0) {
			return Math.ceil(this.total / this.perPage);
		}
		return 1;
	}
	get pagesToDisplay() {
		if (
			this.totalPages > 0 &&
			this.totalPages < this.defaultPagesToDisplay
		) {
			return this.totalPages;
		}
		return this.defaultPagesToDisplay;
	}
	get minPage() {
		if (this.value >= this.pagesToDisplay) {
			const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
			const newMaxPage = pagesToAdd + this.value;
			if (newMaxPage > this.totalPages) {
				return this.totalPages - this.pagesToDisplay + 1;
			}
			return this.value - pagesToAdd;
		} else {
			return 1;
		}
	}
	get maxPage() {
		if (this.value >= this.pagesToDisplay) {
			const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
			const newMaxPage = pagesToAdd + this.value;
			if (newMaxPage < this.totalPages) {
				return newMaxPage;
			} else {
				return this.totalPages;
			}
		} else {
			return this.pagesToDisplay;
		}
	}

	range(min: number, max: number) {
		const arr = [];
		for (let i = min; i <= max; i++) {
			arr.push(i);
		}
		return arr;
	}
	changePage(item: any) {
		this.$emit("input", item);
	}
	nextPage() {
		if (this.value < this.totalPages) {
			this.$emit("input", this.value + 1);
		}
	}
	prevPage() {
		if (this.value > 1) {
			this.$emit("input", this.value - 1);
		}
	}
	lastPage() {
		this.$emit("input", this.totalPages);
	}
	firstPage() {
		this.$emit("input", 1);
	}

	@Watch("perPage")
	onPerPageChanged() {
		this.returnInput;
	}
	@Watch("total")
	onTotalChanged() {
		this.returnInput;
	}

	@Emit("input")
	returnInput() {
		return 1;
	}
}
