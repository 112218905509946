





import { Component, Vue } from "vue-property-decorator";
import SectionsTable from "./content/SectionsTable.vue";
@Component({
	components: {
		SectionsTable
	}
})
export default class SectionsPage extends Vue {}
