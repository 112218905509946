import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { GetAssessmentsTypeResponse } from "@/api/responses/assessmentsTypes/GetAssessmentsTypeResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export class AssessmentsTypes extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public getAssessmentTypes(): Promise<
		ApiResponse<GetAssessmentsTypeResponse[]>
	> {
		return this.get<
			ApiResponse<GetAssessmentsTypeResponse[]>,
			AxiosResponse<ApiResponse<GetAssessmentsTypeResponse[]>>
		>("/AssessmentTypes")
			.then(this.success)
			.catch(this.error);
	}
}
