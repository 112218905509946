



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DropDown extends Vue {
	@Prop({ type: String, default: "down" }) direction!: string;
	@Prop({ type: String }) title!: string;
	@Prop({ type: String }) icon!: string;
	@Prop({ type: String }) position!: string;
	@Prop({ type: Boolean }) hideArrow!: boolean;
	@Prop({ type: String, default: "li" }) tag!: string;
	private isOpen = false;

	toggleDropDown() {
		this.isOpen = !this.isOpen;
		this.$emit("change", this.isOpen);
	}
	closeDropDown() {
		this.isOpen = false;
		this.$emit("change", this.isOpen);
	}
}
