import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GetBannerInfoResponse } from "@/api/responses/settings/GetBannerInfoResponse";

export class Settings extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}


	public getWarningBannerInfo(
		languageCode: string
	): Promise<ApiResponse<GetBannerInfoResponse>> {
		return this.get<
			ApiResponse<GetBannerInfoResponse>,
			AxiosResponse<ApiResponse<GetBannerInfoResponse>>
			>(`/Settings/Banner`, {
				params: languageCode
			})
			.then(this.success)
			.catch(this.error);
	}
}
