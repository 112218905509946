




























import ACTIONS from "@/store/action-definitions";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class ConfirmDeleteModal extends Vue {
	@Prop({ type: Boolean }) showModal!: boolean;
	private isVisible = false;

	@Action(ACTIONS.SET_SHOW_CONFIMRATION_MODAL) setShowConfimrationModal: (
		show: boolean
	) => void;

	@Action(ACTIONS.SET_STATUS_CONFIRMATION_MODAL) setStatusConfirmationModal: (
		status: boolean
	) => void;

	@Getter("getShowConfimrationModal") showConfimrationModal!: boolean;

	@Watch("showConfimrationModal")
	onShowConfimrationModalChange(newVal: boolean) {
		this.isVisible = newVal;
	}

	confirmation(status: boolean): void {
		this.setShowConfimrationModal(false);
		this.setStatusConfirmationModal(status);
	}
}
