import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import store from "@/store";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import ACTIONS from "../../store/action-definitions";
import { LoginRequest } from "../requests/authentication/LoginRequest";
import { JwtToken } from "../responses/authentication/JwtToken";

export class Authentication extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public login(credentials: LoginRequest): Promise<ApiResponse<JwtToken>> {
		return this.post<
			ApiResponse<JwtToken>,
			LoginRequest,
			AxiosResponse<ApiResponse<JwtToken>>
		>("/Authentication/login", credentials)
			.then(this.success)
			.catch(this.error);
	}

	public logout(refreshToken: string): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			string,
			AxiosResponse<ApiResponse<boolean>>
		>("/Authentication/logout", refreshToken)
			.then(this.success)
			.catch(this.error);
	}

	public verifyToken(): Promise<ApiResponse<boolean>> {
		return this.get<
			ApiResponse<boolean>,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Authentication/tokenVerify`)
			.then(this.success)
			.catch(this.error);
	}

	public destroyUserData(): void {
		store.dispatch(ACTIONS.DELETE_SESSION);
	}
}
