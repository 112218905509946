import { AxiosRequestConfig } from "axios";

export const apiConfig: AxiosRequestConfig = {
	withCredentials: false,
	timeout: 30000,
	baseURL: process.env.VUE_APP_API_BASE_URL,
	headers: {
		common: {
			"Cache-Control": "no-cache, no-store, must-revalidate",
			"Pragma": "no-cache",
			"Content-Type": "application/json",
			"Accept": "application/json"
		}
	}
};

export const apiPrinterConfig: AxiosRequestConfig = {
	withCredentials: true,
	timeout: 30000,
	baseURL: process.env.VUE_APP_PRINTER_BASE_URL,
	headers: {
		common: {
			"Cache-Control": "no-cache, no-store, must-revalidate",
			"Pragma": "no-cache",
			"Content-Type": "application/json",
			"Accept": "application/json"
		}
	}
};
