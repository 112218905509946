


























































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppInput extends Vue {
	@Prop({ type: Boolean }) required!: boolean;
	@Prop({ type: String }) label!: string;
	@Prop({ type: String }) error!: string;
	@Prop({ type: String }) labelClasses!: string;
	@Prop({ type: String }) inputClasses!: string;
	@Prop({ type: [String, Number] }) value!: [string, number];
	@Prop({ type: String }) addonRightIcon!: string;
	@Prop({ type: String }) addonLeftIcon!: string;

	private touched = false;
	private focused = false;

	get listeners() {
		return {
			...this.$listeners,
			input: this.updateValue,
			focus: this.onFocus,
			blur: this.onBlur
		};
	}
	get hasIcon() {
		const { addonRight, addonLeft } = this.$slots;
		return (
			addonRight !== undefined ||
			addonLeft !== undefined ||
			this.addonRightIcon !== undefined ||
			this.addonLeftIcon !== undefined
		);
	}

	updateValue(evt: any) {
		const value = evt.target.value;
		if (!this.touched && value) {
			this.touched = true;
		}
		this.$emit("input", value);
	}
	onFocus(value: any) {
		this.focused = true;
		this.$emit("focus", value);
	}
	onBlur(value: any) {
		this.focused = false;
		this.$emit("blur", value);
	}
}
