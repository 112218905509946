import { authenticationService } from "@/api/authenticationServiceInstance";
import cookieHelper from "@/helpers/cookieHelper";
import NotFoundPage from "@/pages/exception/NotFound.vue";
import HomePage from "@/pages/home/Home.vue";
import Login from "@/pages/login/Login.vue";
import ReportEditorPage from "@/pages/reports-editor/ReportsEditor.vue";
import ReportsPage from "@/pages/reports/Reports.vue";
import ReportsGlobalPage from "@/pages/reportsGlobal/ReportsGlobal.vue";
import SectionsPage from "@/pages/sections/Sections.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const isVerified = async () =>
	(await authenticationService.verifyToken()).success;

const routes: Array<RouteConfig> = [
	{
		path: "",
		name: "home",
		component: HomePage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/login",
		name: "login",
		component: Login
	},
	{
		path: "/reports",
		name: "reports",
		component: ReportsPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/editor/report",
		name: "reportEditor",
		component: ReportEditorPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/reports/global",
		name: "reportsGlobal",
		component: ReportsGlobalPage,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/sections",
		name: "sections",
		component: SectionsPage,
		meta: {
			requiresAuth: true
		}
	},
	{ path: "*", name: "notFound", component: NotFoundPage }
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach(async (to, from, next) => {
	const token = cookieHelper.hasSessionCookie();
	if (to.name === "login" && token) {
		next({ name: "home" });
	}
	if (to.matched.some((r) => r.meta.requiresAuth)) {
		if (!token || !(await isVerified())) {
			authenticationService.destroyUserData();
			next({
				name: "login",
				params: {
					redirect: JSON.stringify({
						path: to.path,
						params: to.params,
						query: to.query
					})
				}
			});
			return;
		}
	}
	next();
});

export default router;
