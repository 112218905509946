































import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Card extends Vue {
	@Prop({ type: String }) title!: string;
	@Prop({ type: String }) subTitle!: string;
	@Prop({ type: String }) category!: string;
	@Prop({ type: String }) description!: string;
	@Prop({ type: Boolean }) noFooterLine!: boolean;
	@Prop({ type: Boolean }) plain!: boolean;
	@Prop({ type: [String, Object, Array] }) cardBodyClasses!: [
		string,
		object,
		Array<string>
	];
	@Prop({ type: [String, Object, Array] }) headerClasses!: [
		string,
		object,
		Array<string>
	];
}
