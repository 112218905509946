const reportsEditor = {
	Sections: "Sections",
	Languages: "Languages",
	PickSome: "Pick some...",
	LanguagesSelected: "languages selected",
	ShowChoosenLanguages: "Show choosen languages",
	NoSectionsMessage:
		"No Sections found in this report. Add a Section to start creating a Report.",
	SectionContentSaveSuccess: "Successfully saved section content",
	SaveOrder: "Save order",
	OpenSection: "Open Section",
	EditSection: "Edit Section",
	RemoveSection: "Remove Section",
	CloneSection: "Clone Section",
	PreviewSection: "Preview Section",
	SectionOrderSaveSuccess: "Successfully saved sections order",
	DeleteLanguageSuccess: "Successfully deleted language",
	AddLanguageSuccess: "Successfully added language",
	DetachSectionSuccess: "Successfully detached section",
	SaveSectionSuccess: "Successfully saved section",
	AddTextResourceSuccess: "Successfully added text resource",
	CloneSectionSuccess: "Successfully cloned section",
	SectionNameMustBeUniqueInReport: "Section name must be unique in report"
};

export default reportsEditor;
