import PkButton from "@/components/shared/Button/Button.vue";
import Card from "@/components/shared/Card/Card.vue";
import Dropdown from "@/components/shared/Dropdown/Dropdown.vue";
import PkInput from "@/components/shared/Input/FormGroupInput.vue";
import Modal from "@/components/shared/Modal/Modal.vue";
import Navbar from "@/components/shared/Navbar/Navbar.vue";
import NavbarToggleButton from "@/components/shared/Navbar/NavbarToggleButton.vue";
import Table from "@/components/shared/Table/Table.vue";
import {
	Autocomplete,
	Button,
	Checkbox,
	CheckboxGroup,
	Collapse,
	CollapseItem,
	DatePicker,
	Input,
	InputNumber,
	Menu,
	MenuItem,
	Option,
	Pagination,
	Popover,
	Radio,
	Select,
	TabPane,
	Tabs,
	Tooltip
} from "element-ui";
import locale from "element-ui/lib/locale";
import lang from "element-ui/lib/locale/lang/en";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueClipboard from "vue-clipboard2";
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";

const globalComponents = {
	install(Vue: any): void {
		locale.use(lang);

		Vue.component("Card", Card);
		Vue.component("Navbar", Navbar);
		Vue.component("DropDown", Dropdown);
		Vue.component("PkTable", Table);
		Vue.component("PkInput", PkInput);
		Vue.component("PkButton", PkButton);
		Vue.component("Modal", Modal);

		Vue.component(Input.name, Input);
		Vue.component(InputNumber.name, InputNumber);
		Vue.use(Tooltip);
		Vue.use(MenuItem);
		Vue.use(Popover);
		Vue.use(Checkbox);
		Vue.use(CheckboxGroup);
		Vue.use(Select);
		Vue.use(Menu);
		Vue.use(Option);
		Vue.use(Autocomplete);
		Vue.use(DatePicker);
		Vue.use(Radio);
		Vue.use(Button);
		Vue.use(Collapse);
		Vue.use(CollapseItem);
		Vue.use(Pagination);
		Vue.use(TabPane);
		Vue.use(Tabs);
		Vue.use(VueClipboard);
		Vue.component("NavbarToggleButton", NavbarToggleButton);
		Vue.component("ValidationProvider", ValidationProvider);
		Vue.component("ValidationObserver", ValidationObserver);
		Vue.component("draggable", draggable);
		Vue.component("Multiselect", Multiselect);
	}
};

export default globalComponents;
