var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropDown),expression:"closeDropDown"}],tag:"component",staticClass:"dropdown",class:[
		{ show: _vm.isOpen },
		{ dropdown: _vm.direction === 'down' },
		{ dropup: _vm.direction === 'up' }
	],attrs:{"aria-haspopup":"true","aria-expanded":_vm.isOpen},on:{"click":_vm.toggleDropDown}},[_vm._t("title",function(){return [_c('a',{staticClass:"dropdown-toggle nav-link",class:{ 'no-caret': _vm.hideArrow },attrs:{"data-toggle":"dropdown"}},[_c('i',{class:_vm.icon}),_c('span',{staticClass:"no-icon"},[_vm._v(_vm._s(_vm.title))])])]}),_c('ul',{staticClass:"dropdown-menu",class:[
			{ 'dropdown-menu-right': _vm.position === 'right' },
			{ show: _vm.isOpen }
		]},[_vm._t("default")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }