const reports = {
	Title: "Custom Reports",
	Name: "Name",
	Version: "Version",
	EditReport: "Edit Report",
	ReportPreview: "Report Preview",
	ReportInfo: "Report Info",
	AddReport: "Add Report",
	Description: "Description",
	NoOfProducts: "Products",
	SuccessfullyCreated: "Report successfully created",
	SuccessfullyUpdated: "Report successfully updated",
	SaveAndRunReportEditor: "Save and run report editor",
	DefaultLanguage: "Default Language",
	BaseReport: "Base Report",
	AddReportVersion: "Add to Custom Reports",
	Languages: "Languages",
	Styles: "Styles",
	DownloadPdf: "Download PDF",
	DownloadHtml: "Download HTML",
	DownloadReport: "Download report preview",
	GlobalTitle: "System Reports",
	ShowReport: "Show Report",
	RemoveReport: "Remove Report",
	Id: "Id",
	FieldIsRequired: "Name can't be empty"
};

export default reports;
