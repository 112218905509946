





import { Component, Vue } from "vue-property-decorator";
import ReportsGlobalTable from "./content/ReportsGlobalTable.vue";

@Component({
	components: {
		ReportsGlobalTable
	}
})
export default class ReportsGlobalPage extends Vue {}
