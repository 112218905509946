import { Api } from "@/api/api";
import { ReportSectionOrderRequest } from "@/api/requests/reports/ReportSectionOrderRequest";
import { TableQuery } from "@/api/requests/TableQuery";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { TableData } from "@/types/Table/TableData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { apiPrinterConfig } from "../axios-config";
import { CreateCustomReportRequest } from "../requests/reports/CreateCustomReportRequest";
import { GeneratePdfRequest } from "../requests/reports/GeneratePdfRequest";
import { RenderReportRequest } from "../requests/reports/RenderReportRequest";
import { RenderSectionRequest } from "../requests/reports/RenderSectionRequest";
import { UpdateReportRequest } from "../requests/reports/UpdateReportRequest";
import { ReportDataDto } from "../responses/reports/dtos/ReportDataDto";
import { GetReportsByIdResponse } from "../responses/reports/GetReportsByIdResponse";
import { GetReportsResponse } from "../responses/reports/GetReportsResponse";

export class Reports extends Api {
	constructor(config: AxiosRequestConfig, apiPrinterConfig: AxiosRequestConfig) {
		super(config, apiPrinterConfig);
	}

	public getReports(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetReportsResponse>>> {
		return this.get<
			ApiResponse<TableData<GetReportsResponse>>,
			AxiosResponse<ApiResponse<TableData<GetReportsResponse>>>
		>("/Reports", {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}

	public getGlobalReports(
		tableQuery: TableQuery
	): Promise<ApiResponse<TableData<GetReportsResponse>>> {
		return this.get<
			ApiResponse<TableData<GetReportsResponse>>,
			AxiosResponse<ApiResponse<TableData<GetReportsResponse>>>
		>("/Reports/global", {
			params: tableQuery
		})
			.then(this.success)
			.catch(this.error);
	}

	public getReportsById(
		id: number
	): Promise<ApiResponse<GetReportsByIdResponse>> {
		return this.get<
			ApiResponse<GetReportsByIdResponse>,
			AxiosResponse<ApiResponse<GetReportsByIdResponse>>
		>(`/Reports/${id}`)
			.then(this.success)
			.catch(this.error);
	}

	public updateReports(
		id: number,
		data: UpdateReportRequest
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			UpdateReportRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Reports/${id}`, data)
			.then(this.success)
			.catch(this.error);
	}

	public createReportVersion(
		id: number,
		data: CreateCustomReportRequest
	): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			CreateCustomReportRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Reports/${id}`, data)
			.then(this.success)
			.catch(this.error);
	}

	public reorderReportSections(
		request: ReportSectionOrderRequest
	): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			ReportSectionOrderRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Reports/reorder`, request)
			.then(this.success)
			.catch(this.error);
	}

	public renderSection(
		request: RenderSectionRequest
	): Promise<AxiosResponse<ReportDataDto> | AxiosResponse<unknown>> {
		return this.post<
			ReportDataDto,
			RenderSectionRequest,
			AxiosResponse<ReportDataDto>
		>(`/html`, request, apiPrinterConfig)
			.then(this.successResponse)
			.catch(this.errorResponse);
	}

	public detachSection(
		reportId: number,
		sectionId: number
	): Promise<ApiResponse<boolean>> {
		return this.delete<
			ApiResponse<boolean>,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Reports/${reportId}/section/detach/${sectionId}`)
			.then(this.success)
			.catch(this.error);
	}

	public cloneSection(sectionId: number): Promise<ApiResponse<boolean>> {
		return this.post<
			ApiResponse<boolean>,
			null,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Sections/clone/${sectionId}`, null)
			.then(this.success)
			.catch(this.error);
	}

	public activateLanguage(
		id: number,
		langId: number
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			null,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Reports/${id}/language/activate/${langId}`, null)
			.then(this.success)
			.catch(this.error);
	}

	public deactivateLanguage(
		id: number,
		langId: number
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			null,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Reports/${id}/language/deactivate/${langId}`, null)
			.then(this.success)
			.catch(this.error);
	}

	public renderHtmlReport(
		request: RenderReportRequest
	): Promise<AxiosResponse<ReportDataDto> | AxiosResponse<unknown>> {
		return this.post<
			ReportDataDto,
			RenderReportRequest,
			AxiosResponse<ReportDataDto>
		>(`/html/`, request, apiPrinterConfig)
			.then(this.successResponse)
			.catch(this.errorResponse);
	}

	public generatePdf(request: GeneratePdfRequest): Promise<any> {
		return this.put<string, GeneratePdfRequest, AxiosResponse<any>>(
			`/pdf/html`,
			request,
			{ baseURL: apiPrinterConfig.baseURL, responseType:  "arraybuffer", headers: {"Content-Type": "application/json",
			"Accept": "application/json"}
			}
		)
			.then(this.successResponse)
			.catch(this.errorResponse);
	}

	public removeReport(reportId: number): Promise<ApiResponse<boolean>> {
		return this.delete<
			ApiResponse<boolean>,
			AxiosResponse<ApiResponse<boolean>>
		>(`/Reports/${reportId}`)
			.then(this.success)
			.catch(this.error);
	}
}
