






















































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
	@Prop({ type: Boolean }) show!: boolean;
	@Prop({ type: Boolean, default: true }) showHeader!: boolean;
	@Prop({ type: Boolean, default: true }) showFooter!: boolean;
	@Prop({ type: Boolean, default: true }) showClose!: boolean;
	@Prop({
		type: String,
		default: "",
		validator(value) {
			const acceptedValues = ["", "notice", "mini"];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	type!: string;

	@Prop({ type: [Object, String] }) modalClasses!: [
		Record<string, any>,
		string
	];
	@Prop({ type: [Object, String] }) headerClasses!: [
		Record<string, any>,
		string
	];
	@Prop({ type: [Object, String] }) bodyClasses!: [
		Record<string, any>,
		string
	];
	@Prop({ type: [Object, String] }) footerClasses!: [
		Record<string, any>,
		string
	];
	@Prop({ type: Number, default: 500 }) animationDuration!: number;

	@Prop({ type: Boolean, default: false }) closeOnOuterClick!: boolean;

	closeModal() {
		this.update();
		this.close();
	}

	closeModalOnOuterClick() {
		if (this.closeOnOuterClick) {
			this.closeModal();
		}
	}

	@Emit("close")
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	close() {}
	@Emit("update:show")
	update() {
		return false;
	}
	@Watch("show")
	onShowChanged(val: boolean) {
		const documentClasses = document.body.classList;
		if (val) {
			documentClasses.add("modal-open");
		} else {
			documentClasses.remove("modal-open");
		}
	}
}
