import Sidebar from "./SideBar.vue";
import SidebarItem from "./SideBarItem.vue";

const SidebarStore = {
	showSidebar: false,
	sidebarLinks: [],
	isMinimized: false,
	displaySidebar(value: boolean) {
		this.showSidebar = value;
	},
	toggleMinimize() {
		document.body.classList.toggle("sidebar-mini");
		const simulateWindowResize = setInterval(() => {
			window.dispatchEvent(new Event("resize"));
		}, 180);

		setTimeout(() => {
			clearInterval(simulateWindowResize);
		}, 1000);

		this.isMinimized = !this.isMinimized;
	}
};

declare module "vue/types/vue" {
	interface Vue {
		$sidebar: typeof SidebarStore;
	}
}

const SidebarPlugin = {
	install(Vue: any, options: any) {
		if (options && options.sidebarLinks) {
			SidebarStore.sidebarLinks = options.sidebarLinks;
		}
		const app = new Vue({
			data: {
				sidebarStore: SidebarStore
			}
		});

		Vue.prototype.$sidebar = app.sidebarStore;
		Vue.component("side-bar", Sidebar);
		Vue.component("sidebar-item", SidebarItem);
	}
};

export default SidebarPlugin;
