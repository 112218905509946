import i18n from "@/locales/validationDictionary";
import { configure, extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

configure({
	defaultMessage: (field, values) => {
		values._field_ = i18n.t(`fields.${field}`);
		return i18n.t(`validation.${values._rule_}`, values) as string;
	}
});
