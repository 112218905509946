import { Statable } from "@/interfaces/store/Statable";
import Vue from "vue";
import Vuex from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { state } from "./state";

Vue.use(Vuex);

export default new Vuex.Store<Statable>({
	state,
	mutations,
	actions,
	getters
});
