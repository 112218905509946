



































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Validatorable } from "@/interfaces/Validatorable";
import { GetReportsByIdResponse } from "@/api/responses/reports/GetReportsByIdResponse";
import { Language } from "@/types/Language";
import { GeneratePdfRequest } from "@/api/requests/reports/GeneratePdfRequest";
import { RenderReportRequest } from "@/api/requests/reports/RenderReportRequest";
import { Getter } from "vuex-class/lib";
import { Userable } from "@/interfaces/store/Userable";
import { buildHtml } from "@/helpers/htmlReportBuilderHelper";
import { ReportDataDto } from "@/api/responses/reports/dtos/ReportDataDto";
import { ReportSectionDto } from "@/api/responses/reports/dtos/ReportSectionDto";

@Component
export default class ReportPreview extends Vue {
	@Prop({ type: Boolean }) globalReports!: boolean;
	private data = this.getEmptyReport();
	private loading = false;
	private languageChoosen = "";
	private languageChoosenId = 0;
	private downloadingPdf = false;
	private downloadingHtml = false;
	private styleChoosen = 1;
	private paperSizeChoosen = "letter";

	private styleOptions = [
		{ label: "app 2", value: 1, code: "app2" },
		{ label: "app 3", value: 2, code: "app3" },
		{ label: "app 4", value: 3, code: "app4" }
	];

	$refs!: {
		form: Validatorable;
	};

	@Getter("user") user!: Userable | null;

	getEmptyReport(): GetReportsByIdResponse {
		return {
			id: 0,
			name: "",
			description: ""
		} as GetReportsByIdResponse;
	}

	async fill(id: number): Promise<void> {
		this.loading = true;
		this.data = this.getEmptyReport();
		const { data, success } = await this.$reports.getReportsById(id);
		if (success) {
			this.data = data;
			this.languageChoosen = data.defaultLanguage.name;
			this.languageChoosenId = data.defaultLanguage.id;
		}
		this.loading = false;
	}

	getLanguages(
		queryString: string,
		callBack: (data: Language[]) => void
	): void {
		callBack(this.data.availableLanguages);
	}

	handleSelectLanguages(item: Language): void {
		this.languageChoosen = item.name;
		this.languageChoosenId = item.id;
	}

  async downloadPdf(): Promise<void> {
		this.downloadingPdf = true;

		const response = await this.$reports.generatePdf({
			languageId: this.languageChoosenId,
			assessmentId: 0,
			reportId: this.data.id,
			specificSectionIds: [],
			reportStyle: this.styleOptions.find(
				(style) => style.value === this.styleChoosen
			)?.code,
			paperSize: this.paperSizeChoosen,
			globalReports: this.globalReports
		} as GeneratePdfRequest);
		if (response.status === 200) 
		{
			if(response.data)
			{
				const newBlob = new Blob([response.data], { type: "application/pdf" });
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(newBlob);
					return;
				}

				const data = window.URL.createObjectURL(newBlob);
				const link = document.createElement("a");
				link.setAttribute("href", data);
				link.setAttribute("target", "_blank");
				link.click();
				setTimeout(() => {
					window.URL.revokeObjectURL(data);
				}, 100);
			}
		}
		this.downloadingPdf = false;
	}

	async downloadHtml(): Promise<void> {
		this.downloadingHtml = true;
		const response = await this.$reports.renderHtmlReport({
			languageId: this.languageChoosenId,
			reportId: this.data.id,
			assessmentId: 0,
			specificSectionIds: [],
			reportStyle: this.styleOptions.find(
				(style) => style.value === this.styleChoosen
			)?.code
		} as RenderReportRequest);
		
		if (response.status === 200) 
		{
			const html = buildHtml(response.data as ReportDataDto);
			
			const url = window.URL.createObjectURL(
				new Blob([html], { type: "text/html" })
			);
			const link = document.createElement("a");

			link.setAttribute("href", url);
			link.setAttribute("target", "_blank");
			link.click();
		}
		this.downloadingHtml = false;
	}
}
