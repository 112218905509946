import { GetAssessmentContext } from "@/api/responses/assessments/GetAssessmentContext";
import { GetAssessmentsTypeResponse } from "@/api/responses/assessmentsTypes/GetAssessmentsTypeResponse";
import { ReportSectionDto } from "@/api/responses/reports/dtos/ReportSectionDto";
import { Languable } from "@/interfaces/store/Languable";
import { Messageable } from "@/interfaces/store/Messageable";
import { Statable } from "@/interfaces/store/Statable";
import { Userable } from "@/interfaces/store/Userable";
import { StoreSection } from "@/types/Editor/StoreSection";
import { MutationTree } from "vuex";
import MUTATIONS from "./mutation-definitions";

export const mutations: MutationTree<Statable> = {
	[MUTATIONS.SET_EDITOR_SECTIONS]: (state, sections: ReportSectionDto[]) => {
		state.editorSections = sections;
		if (
			!sections.some(
				(section) => section.id === state.currentSectionId
			) &&
			!state.isSectionDetached
		) {
			state.currentSectionId = sections[0]?.id ?? 0;
		}
	},
	[MUTATIONS.SET_CURRENT_SECTION]: (state, sectionId: number) =>
		(state.currentSectionId = sectionId),
	[MUTATIONS.SET_MESSAGE]: (state, message: Messageable) =>
		(state.message = message),
	[MUTATIONS.SET_EDITOR_DIRTY]: (state, isDirty: boolean) =>
		(state.isEditorDirty = isDirty),
	[MUTATIONS.SET_USER]: (state, user: Userable | null) => (state.user = user),

	[MUTATIONS.SET_LANGUAGES]: (state, languages: Languable[]) =>
		(state.languages = languages),
	[MUTATIONS.SET_LANGUAGE]: (state, locale: string) => {
		const availableLanguage = state.languages.find((language) =>
			language.locale.startsWith(locale)
		);
		if (availableLanguage) {
			state.userLanguage = availableLanguage;
		}
	},
	[MUTATIONS.SET_ASSESSMENT_CONTEXT]: (
		state,
		assessmentcontext: GetAssessmentContext
	) => {
		state.assessmentContext = assessmentcontext;
	},
	[MUTATIONS.SET_TABS_TOUCHED_TEXT]: (state, tabsTouchedText: boolean) => {
		state.tabsTouchedText = tabsTouchedText;
	},
	[MUTATIONS.SET_TABS_TOUCHED_RULES]: (state, tabsTouchedRules: boolean) => {
		state.tabsTouchedRules = tabsTouchedRules;
	},
	[MUTATIONS.SET_ASSESSMENT_TYPES]: (
		state,
		assessmentTypes: Array<GetAssessmentsTypeResponse>
	) => {
		state.assessmentTypes = assessmentTypes;
	},
	[MUTATIONS.SET_PREVIEW_HTML]: (state, previewHtml: string) => {
		state.previewHtml = previewHtml;
	},
	[MUTATIONS.SET_SECTION_ID]: (state, sectionId: number) => {
		state.sectionId = sectionId;
	},
	[MUTATIONS.SET_SECTION_NAME]: (state, sectionName: string) => {
		state.sectionName = sectionName;
	},
	[MUTATIONS.SET_SHOW_CONFIMRATION_MODAL]: (state, show: boolean) => {
		state.showConfimrationModal = show;
	},
	[MUTATIONS.SET_STATUS_CONFIRMATION_MODAL]: (state, status: boolean) => {
		state.statusConfirmationModal = status;
	},
	[MUTATIONS.SET_PREVIEW_ERRORS]: (state, previewErrors: string[]) => {
		state.previewErrors = previewErrors;
	},
	[MUTATIONS.SET_SECTION]: (state, section: StoreSection) => {
		state.section = section;
	},
	[MUTATIONS.SET_SECTION_DETACHED]: (state, isSectionDetached: boolean) => {
		state.isSectionDetached = isSectionDetached;
	}
};
