import { Languable } from "@/interfaces/store/Languable";
import store from "@/store";
import cookie from "vue-cookies";

const SESSION_COOKIE_NAME = "common_token";
const LANGUAGE_COOKIE_NAME = "app_lang";

const cookieDomainName =
	process.env.VUE_APP_COOKIE_DOMAIN === "localhost"
		? ""
		: process.env.VUE_APP_COOKIE_DOMAIN;
const expirationTime = "8h";

export default {
	getSessionCookie() {
		return cookie.get(SESSION_COOKIE_NAME);
	},
	getLanguageCookie() {
		return cookie.get(LANGUAGE_COOKIE_NAME);
	},
	deleteSessionCookie() {
		cookie.remove(SESSION_COOKIE_NAME);
	},
	deleteLanguageCookie() {
		cookie.remove(LANGUAGE_COOKIE_NAME);
	},
	hasSessionCookie() {
		return !!cookie.get(SESSION_COOKIE_NAME);
	},
	hasLanguageCookie() {
		return !!cookie.get(LANGUAGE_COOKIE_NAME);
	},
	setSessionCookie(token: string) {
		cookie.set(
			SESSION_COOKIE_NAME,
			token,
			expirationTime,
			"/",
			cookieDomainName
		);
	},
	setLanguageCookie(newLocale: string) {
		const locale = `${newLocale
			.slice(0, 2)
			.toLowerCase()}-${newLocale.slice(3, 5).toUpperCase()}`;
		const isReal = store.getters.getLanguages
			.map((item: Languable) => item.locale)
			.includes(locale);

		cookie.set(
			LANGUAGE_COOKIE_NAME,
			isReal ? locale : store.getters.getShortLocale,
			expirationTime,
			"/",
			cookieDomainName
		);
	},
	generateLanguageCookie() {
		let locale = window.navigator.language;
		if (locale.length === 2)
			return (locale = `${locale}-${locale.toUpperCase()}`);

		return store.state.defaultLanguage.locale;
	}
};
