var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',{attrs:{"backgroundColor":"white"}},[_c('div',{staticClass:"d-flex align-items-center py-2 sidebar-toggler",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleSidebar.apply(null, arguments)}}},[_c('div',{staticClass:"navbar-toggle",class:{ toggled: _vm.$sidebar.showSidebar }},[_c('navbar-toggle-button')],1)]),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
				name: _vm.$t('layout.Reports'),
				icon: 'now-ui-icons files_single-copy-04'
			}}},[_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('layout.CustomReports'),
					path: 'reports',
					child: true
				}}}),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('layout.GlobalReports'),
					path: 'reports/global',
					child: true
				}}}),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('layout.Sections'),
					path: 'sections',
					child: true
				}}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }