import errors from "./errors";
import files from "./files";
import languages from "./languages";
import layout from "./layout";
import login from "./login";
import modals from "./modals";
import reports from "./reports";
import reportsEditor from "./reportsEditor";
import sections from "./sections";
import table from "./table";

const en = {
	table,
	reports,
	errors,
	reportsEditor,
	login,
	sections,
	languages,
	layout,
	files,
	modals
};

export default en;
