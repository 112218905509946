const sections = {
	Title: "Section",
	SelectPlaceholder: "Select",
	Name: "Name",
	Type: "Type",
	AddTemplate: "Add template content",
	TableTitle: "Sections",
	Id: "Id",
	Remove: "Remove selected",
	NoOfReports: "# of reports",
	DeleteTooltip: "Delete section",
	AlreadyExists: "Section already exists in this report",
	CloneSection: "Clone section",
	CloneName: "New section name"
};

export default sections;
