import { Api } from "@/api/api";
import { ApiResponse } from "@/api/responses/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { SaveSectionContentRequest } from "../requests/sectionContents/SaveSectionContentRequest";

export class SectionContents extends Api {
	constructor(config: AxiosRequestConfig) {
		super(config);
	}

	public saveSectionContent(
		sectionId: number,
		data: SaveSectionContentRequest
	): Promise<ApiResponse<boolean>> {
		return this.put<
			ApiResponse<boolean>,
			SaveSectionContentRequest,
			AxiosResponse<ApiResponse<boolean>>
		>(`/SectionContents/${sectionId}`, data)
			.then(this.success)
			.catch(this.error);
	}
}
