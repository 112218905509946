















































import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppSidebar extends Vue {
	public toggleSidebar(): void {
		this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
	}
}
