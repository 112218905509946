







































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Notification extends Vue {
	@Prop({ type: String }) message!: string;
	@Prop({ type: String }) title!: string;
	@Prop({ type: String }) icon!: string;
	@Prop({
		type: String,
		default: "top",
		validator: (value) => {
			const acceptedValues = ["top", "bottom"];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	verticalAlign!: string;
	@Prop({
		type: String,
		default: "right",
		validator: (value) => {
			const acceptedValues = ["left", "center", "right"];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	horizontalAlign!: string;
	@Prop({
		type: String,
		default: "info",
		validator: (value) => {
			const acceptedValues = [
				"info",
				"primary",
				"danger",
				"warning",
				"success"
			];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	type!: string;
	@Prop({
		type: Number,
		default: 5000,
		validator: (value) => {
			return value >= 0;
		}
	})
	timeout!: number;
	@Prop({ type: Date, default: () => new Date() }) timestamp!: Date;
	@Prop({ type: [Object, Function] }) component!: [
		Record<string, any>,
		Function
	];
	@Prop({ type: Boolean, default: true }) showClose!: boolean;
	@Prop({ type: Boolean, default: true }) closeOnClick!: boolean;
	@Prop({ type: Function }) clickHandler!: Function;

	private elmHeight = 0;

	get hasIcon() {
		return this.icon && this.icon.length > 0;
	}
	get alertType() {
		return `alert-${this.type}`;
	}
	get customPosition() {
		const initialMargin = 20;
		const alertHeight = this.elmHeight + 10;
		let sameAlertsCount = this.$notifications.state.filter((alert) => {
			return (
				alert.horizontalAlign === this.horizontalAlign &&
				alert.verticalAlign === this.verticalAlign &&
				alert.timestamp <= this.timestamp
			);
		}).length;
		if (this.$notifications.settings.overlap) {
			sameAlertsCount = 1;
		}
		const pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
		const styles = {} as any;
		if (this.verticalAlign === "top") {
			styles.top = `${pixels}px`;
		} else {
			styles.bottom = `${pixels}px`;
		}
		return styles;
	}

	close() {
		this.$emit("close", this.timestamp);
	}
	tryClose(evt: any) {
		if (this.clickHandler) {
			this.clickHandler(evt, this);
		}
		if (this.closeOnClick) {
			this.close();
		}
	}

	mounted() {
		this.elmHeight = this.$el.clientHeight;
		if (this.timeout) {
			setTimeout(this.close, this.timeout);
		}
	}
}
