import { sharedI18n } from "@peoplekeys/shared-components/src";
import Vue from "vue";
import VueI18n from "vue-i18n";
import english from "./en-US/index";

const localization = {
	"en-US": { ...english, ...sharedI18n["en-US"] }
};

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en-US",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en-US",
	messages: localization
});

export default i18n;
