const files = {
	Title: "Files",
	CopyURL: "Copy URL",
	CopyTag: "Copy Image tag",
	ShowFiles: "Show files",
	PublicImagesTab: "Public images",
	MyImagesTab: "My Images",
	UploadImagesTab: "Upload Images",
	UploadImages: "Upload",
	LoadFiles: "Load files"
};

export default files;
