






















































































import { Component, Vue } from "vue-property-decorator";
import { Validatorable } from "@/interfaces/Validatorable";
import ACTIONS from "@/store/action-definitions";
import { Action } from "vuex-class";
import { JwtToken } from "@/api/responses/authentication/JwtToken";

@Component
export default class LoginForm extends Vue {
	private email = "";
	private password = "";
	private isLoading = false;

	$refs!: {
		form: Validatorable;
	};

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	@Action(ACTIONS.SET_SESSION) setSession: (tokens: JwtToken) => void;

	async checkForm(): Promise<void> {
		this.clearErrors();
		const isValid = await this.$refs.form.validate();
		if (isValid) await this.login();
	}
	async login(): Promise<void> {
		this.isLoading = true;
		const { data, success } = await this.$authentication.login({
			username: this.email,
			password: this.password
		});

		if (success) {
			this.setSession(data);
			if (this.$route.params.redirect)
				this.$router.push({
					...JSON.parse(this.$route.params.redirect)
				});
			else this.$router.push({ name: "home" });
		}
		this.isLoading = false;
	}

	clearErrors(): void {
		this.$refs.form.reset();
	}
}
