enum MUTATIONS {
	SET_EDITOR_SECTIONS = "setEditorSections",
	SET_CURRENT_SECTION = "setCurrentSection",
	SET_MESSAGE = "setMessage",
	SET_EDITOR_DIRTY = "setEditorDirty",
	SET_USER = "setUser",
	SET_LANGUAGES = "setLanguages",
	SET_LANGUAGE = "setLanguage",
	SET_ASSESSMENT_CONTEXT = "setAssessmentContext",
	SET_TABS_TOUCHED_TEXT = "setTabsTouchedText",
	SET_TABS_TOUCHED_RULES = "setTabsTouchedRules",
	SET_ASSESSMENT_TYPES = "setAssessmentTypes",
	SET_PREVIEW_HTML = "setPreviewHtml",
	SET_SECTION_ID = "setSectionId",
	SET_SECTION_NAME = "setSectionName",
	SET_SHOW_CONFIMRATION_MODAL = "setShowConfimrationModal",
	SET_STATUS_CONFIRMATION_MODAL = "setStatusConfirmationModal",
	SET_PREVIEW_ERRORS = "setPreviewErrors",
	SET_SECTION = "setSection",
	SET_SECTION_DETACHED = "setSectionDetached"
}

export default MUTATIONS;
